import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, MenuOutlined, UserOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Layout, Avatar } from 'antd';
import laundroLogo from '../images/logo/laundrologo.png';
import cleanproLogo from '../images/logo/cleanprologo-header.png';
import octopusLogo from '../images/logo/octopus-header.png';
import aerosparkleLogo from '../images/logo/aerosparklelogo.png';
import washstudioLogo from '../images/logo/washstudiologo.png';
import cuciLogo from '../images/logo/cuciLogo.png';
import bubblepulseLogo from '../images/logo/bubblepulseLogo.png';

import avatar from '../images/avatars/thumb-13.jpg';
import cleanproBear from '../images/avatars/cleanpro-bear.png';
import ProfileDropDown from './ProfileDropDown';
import { useSelector } from 'react-redux';
import LanguageChanger from './LanguageChanger';
const { Header } = Layout;

const HeaderComponent = ({ collapsed, setCollapsed, setShowDrawer, showDrawer }) => {
	const { theme } = useSelector((state) => state.theme);
	const [logo, setLogo] = useState(null);
	const [headerAvatar, setHeaderAvatar] = useState(null);

	useEffect(() => {
		if (theme === 'laundro') {
			setLogo(laundroLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'cleanpro') {
			setLogo(cleanproLogo);
			setHeaderAvatar(cleanproBear);
		} else if (theme === 'octopus') {
			setLogo(octopusLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'washstudio') {
			setLogo(washstudioLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'cuci') {
			setLogo(cuciLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'aerosparkle') {
			setLogo(aerosparkleLogo);
			setHeaderAvatar(avatar);
		} else if (theme === 'bubblespulse') {
			setLogo(bubblepulseLogo);
			setHeaderAvatar(avatar);
		}
	}, [theme]);

	return (
		<div>
			<Header className='site-layout-background header-content-navbar' mode='horizontal'>
				<div>
					{collapsed ? null : (
						<img
							className='laundro-logo ms-4'
							src={logo}
							style={{ width: '100%', objectFit: 'contain', marginTop: theme === 'washstudio' ? '1rem' : '0' }}
							alt='brand'
						/>
					)}
				</div>
				<div className='header-navbar-links'>
					{/* // desktop view */}
					<div onClick={() => setCollapsed(!collapsed)} style={{ color: '#fff' }} className='desktop-view-menu'>
						{collapsed ? (
							<ArrowLeftOutlined className='icons-styling' />
						) : (
							<MenuOutlined className='icons-styling ms-5' />
						)}
					</div>

					{/* // this is for mobile view */}
					<div onClick={() => setShowDrawer(true)} style={{ color: '#fff' }} className='mobile-view-menu'>
						<MenuOutlined className='icons-styling' />
					</div>

					<div className='d-flex align-items-center'>
						<div className='my-select-container mb-2'>
							<LanguageChanger />
						</div>

						{/* <div>
							<ProfileDropDown>
								<div className='logo-avatar'>
									<Avatar
										src={headerAvatar}
										style={{ objectFit: 'contain', height: '55px', width: '55px' }}
										alt="avatar"
									/>
								</div>
							</ProfileDropDown>
						</div> */}

						{/* <div className="d-flex align-items-center" style={{ height: '55px', marginRight: '20px' }}>
							<a href="https://info.thelaundro.com/faq/" target="_blank" rel="noopener noreferrer" className="d-flex align-items-center">
								<QuestionCircleTwoTone style={{ fontSize: '34px' }} />
							</a>
						</div> */}

						<div style={{ display: 'flex', alignItems: 'center' }}>
							<ProfileDropDown>
								<div className='logo-avatar'>
									<Avatar
										size={45}
										icon={<UserOutlined style={{ color: 'white', fontSize: '30px' }} />}
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											backgroundColor: '#3baea0'
										}}
										alt="user icon"
									/>
								</div>
							</ProfileDropDown>

							<a href="https://info.thelaundro.com/faq/" target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
								<Avatar
									size={45}
									icon={<QuestionCircleOutlined style={{ color: 'white', fontSize: '30px' }} />}
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										backgroundColor: '#3baea0'
									}}
									alt="FAQ icon"
								/>
							</a>
						</div>
					</div>
				</div>
			</Header>
		</div>
	);
};

export default HeaderComponent;
