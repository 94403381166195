import React, { useEffect, useState } from 'react';
import { Dropdown, Avatar } from 'antd';
import { PoweroffOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons';
import avatar from '../images/avatars/thumb-13.jpg';
import cleanproBear from '../images/avatars/cleanpro-bear.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { LOGOUT_SUCCESS } from '../reducer/userReducer';
import { useTranslation } from 'react-i18next';

const ProfileDropDown = ({ children }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { theme } = useSelector((state) => state.theme);
	const { operator, distributor, staff, error } = useSelector((state) => state.user);
	const [brand, setBrand] = useState('');
	const [username, setUsername] = useState('');
	const [headerAvatar, setHeaderAvatar] = useState(null);
	const { t } = useTranslation();

	const navigate = useNavigate();
	const stateData = location.state;

	function logout() {
		localStorage.clear();
		dispatch({ type: LOGOUT_SUCCESS });
		if (stateData && stateData.userType) {
			navigate(`/${stateData.userType}_login`);
		} else {
			navigate('/operator_login');
		}
	}

	useEffect(() => {
		if (theme === 'laundro') {
			setBrand('The Laundro');
			setHeaderAvatar(avatar);
		}
		if (theme === 'cleanpro') {
			setBrand('Cleanpro');
			setHeaderAvatar(cleanproBear);
		}
		if (theme === 'octopus') {
			setBrand('Washupp');
			setHeaderAvatar(avatar);
		}

		if (theme === 'washstudio') {
			setBrand('Wash Studio');
			setHeaderAvatar(avatar);
		}

		if (theme === 'cuci') {
			setBrand('Cuci Express');
			setHeaderAvatar(avatar);
		}

		if (theme === 'aerosparkle') {
			setBrand('Aerosparkle');
			setHeaderAvatar(avatar);
		}

		if (theme === 'bubblespulse') {
			setBrand('Bubble Pulse');
			setHeaderAvatar(avatar);
		}

		if (distributor) {
			setUsername(distributor.data?.username);
		}
		if (operator) {
			setUsername(operator.data?.username);
		}
		if (staff) {
			setUsername(staff.data?.username);
		}
	}, [theme, operator, distributor, staff]);

	const items = [
		{
			key: '1',
			label: (
				<div className='d-flex align-items-center border-bottom py-1'>
					<Avatar
						size={45}
						icon={<UserOutlined style={{ color: 'white', fontSize: '30px' }} />}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#3baea0'
						}}
						alt="user icon"
					/>
					<div className='mx-2'>
						<div>{username}</div>
						<small className='text-muted'>{brand}</small>
					</div>
				</div>
			),
		},
		{
			key: '2',
			label: (
				<div
					onClick={() => {
						navigate('/op_setting', { state: stateData });
					}}
					className='d-flex align-items-center py-1'
				>
					<SettingOutlined />
					<div className='mx-2 text-muted'>{t('Setting')}</div>
				</div>
			),
		},
		// {
		//   key: "3",
		//   label: (
		//     <Link to="/op_profile" className="d-flex align-items-center py-1">
		//       <UserOutlined />

		//       <div className="mx-2 text-muted">Profile</div>
		//     </Link>
		//   ),
		// },
		{
			key: '3',
			label: (
				<div className='d-flex align-items-center py-1' onClick={logout}>
					<PoweroffOutlined />

					<div className='mx-2 text-muted'>{t('Logout')}</div>
				</div>
			),
		},
	];

	const itemsStaff = [
		{
			key: '1',
			label: (
				<div className='d-flex align-items-center border-bottom py-1'>
					<Avatar
						size={45}
						icon={<UserOutlined style={{ color: 'white', fontSize: '30px' }} />}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: '#3baea0'
						}}
						alt="user icon"
					/>
					<div className='mx-2'>
						<div>{username}</div>
						<small className='text-muted'>{brand}</small>
					</div>
				</div>
			),
		},
		{
			key: '2',
			label: (
				<div className='d-flex align-items-center py-1' onClick={logout}>
					<PoweroffOutlined />

					<div className='mx-2 text-muted'>Logout</div>
				</div>
			),
		},
	];

	return (
		<div style={{ width: '90px' }}>
			{stateData?.userType === 'staff' ? (
				<Dropdown menu={{ items: itemsStaff }} placement='bottomRight'>
					{children}
				</Dropdown>
			) : (
				<Dropdown menu={{ items }} placement='bottomRight'>
					{children}
				</Dropdown>
			)}
		</div>
	);
};

export default ProfileDropDown;
