import { getToken, returnError } from './helperFunc';
import { fetchMachineDetailsAction } from './requestEndpoints';

const SET_MACHINE_DETAILS_LOADING = 'SET_MACHINE_DETAILS_LOADING';
const FETCH_MACHINE_DETAILS_SUCCESS = 'FETCH_MACHINE_DETAILS_SUCCESS';
const FETCH_MACHINE_DETAILS_FAIL = 'FETCH_MACHINE_DETAILS_FAIL';

export const fetchMachineDetails = (operatorId, filterMonth, filterYear, paginationNumber) => async (dispatch, getState) => {
    dispatch({ type: SET_MACHINE_DETAILS_LOADING });
    try {
        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchMachineDetailsAction(operatorId, filterMonth, filterYear, paginationNumber, token);
        dispatch({ type: FETCH_MACHINE_DETAILS_SUCCESS, payload: data });
        return { payload: { data } };
    } catch (error) {
        dispatch({ type: FETCH_MACHINE_DETAILS_FAIL, payload: returnError(error) });
        throw error;
    }
};

const initialState = {
    machines: [],
    loading: false,
    error: null,
};

export const machineEfficiencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MACHINE_DETAILS_LOADING':
            return {
                ...state,
                loading: true,
                error: null
            };
        case 'FETCH_MACHINE_DETAILS_SUCCESS':
            return {
                ...state,
                machines: action.payload,
                loading: false
            };
        case 'FETCH_MACHINE_DETAILS_FAIL':
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case 'LOGOUT_SUCCESS':
            return initialState;
        default:
            return state;
    }
};