// salesStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    getSalesTypeAction,
    addSalesTypeAction,
    assignSalesTypeAction,
    editSalesTypeAction,
    deleteSalesTypeAction,
    ManageSalesAction,
    fetchSalesOutletListAction
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_SALES_TYPE_LOADING = 'SET_SALES_TYPE_LOADING';
const CLEAR_SALES_TYPE_ERROR = 'CLEAR_SALES_TYPE_ERROR';

const ADD_SALES_TYPE_SUCCESS = 'ADD_SALES_TYPE_SUCCESS';
const ADD_SALES_TYPE_FAIL = 'ADD_SALES_TYPE_FAIL';

const ASSIGN_SALES_TYPE_SUCCESS = 'ASSIGN_SALES_TYPE_SUCCESS';
const ASSIGN_SALES_TYPE_FAIL = 'ASSIGN_SALES_TYPE_FAIL';

const GET_SALES_TYPE_LIST_SUCCESS = 'GET_SALES_TYPE_LIST_SUCCESS';
const GET_SALES_TYPE_LIST_FAIL = 'GET_SALES_TYPE_LIST_FAIL';

const DELETE_SALES_TYPE_SUCCESS = 'DELETE_SALES_TYPE_SUCCESS';
const DELETE_SALES_TYPE_FAIL = 'DELETE_SALES_TYPE_FAIL';

const UPDATE_SALES_TYPE_SUCCESS = 'UPDATE_SALES_TYPE_SUCCESS';
const UPDATE_SALES_TYPE_FAIL = 'UPDATE_SALES_TYPE_FAIL';

const CLEAR_REMOVED_FLAG = 'CLEAR_REMOVED_FLAG';

const MANAGE_SALES_SUCCESS = 'MANAGE_SALES_SUCCESS';
const MANAGE_SALES_FAIL = 'MANAGE_SALES_FAIL';

const FETCH_SALES_DETAILS = 'FETCH_SALES_DETAILS';
const FETCH_SALES_DETAILS_FAIL = 'FETCH_SALES_DETAILS_FAIL';



// FOR SALES TYPE SETTING PAGE

export const getSalesType = (operatorId) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SALES_TYPE_ERROR });
        dispatch({ type: SET_SALES_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await getSalesTypeAction(operatorId, token);

        dispatch({ type: GET_SALES_TYPE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_SALES_TYPE_LIST_FAIL, payload: returnError(error) });
    }
};

export const addSalesType = (newSalesType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SALES_TYPE_ERROR });
        dispatch({ type: SET_SALES_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await addSalesTypeAction(newSalesType, token);

        dispatch({ type: ADD_SALES_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADD_SALES_TYPE_FAIL, payload: returnError(error) });
    }
};

export const assignSalesType = (newSalesType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SALES_TYPE_ERROR });
        dispatch({ type: SET_SALES_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await assignSalesTypeAction(newSalesType, token);

        dispatch({ type: ASSIGN_SALES_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ASSIGN_SALES_TYPE_FAIL, payload: returnError(error) });
    }
};


export const editSalesType = (updateData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SALES_TYPE_ERROR });
        dispatch({ type: SET_SALES_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await editSalesTypeAction(updateData, token);

        dispatch({ type: UPDATE_SALES_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_SALES_TYPE_FAIL, payload: returnError(error) });
    }
};

export const deleteSalesType = (resBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SALES_TYPE_ERROR });
        dispatch({ type: SET_SALES_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await deleteSalesTypeAction(resBody, token);

        dispatch({ type: DELETE_SALES_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: DELETE_SALES_TYPE_FAIL, payload: returnError(error) });
    }
};

export const clearRemovedFlag = () => ({
    type: CLEAR_REMOVED_FLAG,
});


export const fetchSalesOutletList = (reqBody) => async (dispatch, getState) => {
    try {
        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchSalesOutletListAction(reqBody, token);

        dispatch({ type: FETCH_SALES_DETAILS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_SALES_DETAILS_FAIL,
            payload: returnError(error),
        });
    }
};

export const ManageSales = (reqBody) => async (dispatch, getState) => {
    try {
        const { user } = getState();
        const token = getToken(user);

        const { data } = await ManageSalesAction(reqBody, token);

        dispatch({ type: MANAGE_SALES_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: MANAGE_SALES_FAIL, payload: returnError(error) });
    }
};

const initialState = {
    language: 'en',
    salesTypeList: null,
    loading: false,
    salesTypeLists: [],
    salesDetails: [],
};

export const salesTypeSettingReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SALES_TYPE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_SALES_TYPE_ERROR:
            return {
                ...state,
                error: null,
            };

        case GET_SALES_TYPE_LIST_SUCCESS:
            return {
                ...state,
                salesTypeLists: action.payload.data,
                loading: false,
                error: null,
            };
        case ADD_SALES_TYPE_SUCCESS:
            return {
                ...state,
                salesType: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case ASSIGN_SALES_TYPE_SUCCESS:
            return {
                ...state,
                assigned: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case UPDATE_SALES_TYPE_SUCCESS:
            return {
                ...state,
                salesTypeUpdated: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case DELETE_SALES_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                removed: true,
            };

        case MANAGE_SALES_SUCCESS:
            return {
                ...state,
                salesPriceUpdated: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case FETCH_SALES_DETAILS:
            return {
                ...state,
                salesDetails: action.payload.data,
                loading: false,
                error: null,
            };

        case CLEAR_REMOVED_FLAG:
            return {
                ...state,
                removed: false,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        case GET_SALES_TYPE_LIST_FAIL:
        case ASSIGN_SALES_TYPE_FAIL:
        case UPDATE_SALES_TYPE_FAIL:
        case DELETE_SALES_TYPE_FAIL:
        case MANAGE_SALES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
