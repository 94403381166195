import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import SideBarComponent from './SideBarComponent';
import HeaderComponent from './HeaderComponent';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkUserSession } from '../reducer/userReducer';
import CustomSidebarComponent from './CustomSidebarComponent';
import { useTranslation } from 'react-i18next';
const { Content } = Layout;

const MainPageLayout = ({ children, devSideBars, franchisorSideBars, adminSideBars }) => {
	const { i18n } = useTranslation();
	let [searchParams] = useSearchParams();
	const userType = searchParams.get('userType');
	const loyaltyLanguage = searchParams.get('language');
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState(false);
	const [showDrawer, setShowDrawer] = useState(false);
	const { operator, distributor, staff } = useSelector((state) => state.user);

	const userReducer = useSelector((state) => state.user);

	const currentPath = window.location.pathname;

	// console.log('current path: ', currentPath, loyaltyLanguage);

	const stateData = location.state;
	const token = userReducer && userReducer[`${(stateData && stateData.userType) || userType}Token`];

	useEffect(() => {
		if (token) {
			dispatch(checkUserSession(token));
		} else {
			window.location.href = stateData && stateData.userType ? `/${stateData.userType}_login` : '/operator_login';
		}
	}, [dispatch, token]);

	useEffect(() => {
		if (userType) {
			navigate(currentPath, { state: { userType } });
		}

		if (loyaltyLanguage) {
			localStorage.setItem('laundro-language', loyaltyLanguage);
			i18n.changeLanguage(loyaltyLanguage);
		}
	}, [navigate, userType, currentPath]);

	// console.log('token', token);
	//  console.log('stateData', stateData);
	// console.log('stateData', stateData);
	// console.log('token', token);
	// console.log('userReducer', userReducer);

	return (
		<div>
			<Layout hasSider>
				{devSideBars ? (
					<SideBarComponent
						showDrawer={showDrawer}
						setShowDrawer={setShowDrawer}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
					/>
				) : (
					<CustomSidebarComponent
						devSideBars={devSideBars}
						showDrawer={showDrawer}
						setShowDrawer={setShowDrawer}
						collapsed={collapsed}
						setCollapsed={setCollapsed}
					/>
				)}

				<Layout
					style={{ background: '#f7fbff' }}
					className={collapsed ? 'sidebar-width-container-collapse' : 'sidebar-width-container-xcollapse'}
				>
					{devSideBars ? (
						<HeaderComponent
							setShowDrawer={setShowDrawer}
							showDrawer={showDrawer}
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						/>
					) : (
						<HeaderComponent
							setShowDrawer={setShowDrawer}
							showDrawer={showDrawer}
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						/>
					)}
					{operator || distributor || staff ? <Content className='content-container'>{children}</Content> : null}
				</Layout>
			</Layout>
		</div>
	);
};

export default MainPageLayout;
