import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { checkDoPaymentUrl } from '../../../reducer/requestEndpoints';

const DoPaymentCheck = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	const [text, setText] = useState('Redirecting ...');

	// machineNo=uthma11-d1&outletCode=c64a3d60153ede56707fe4aaf11a416cb6e1bb0677d3e870f09d2eae711ac731&operatorCode=c31a7d7b1f70c35a3a31e6aab413152ce1bfbd5f3589b92c

	const operatorCode = searchParams.get('operatorCode');
	const machineNo = searchParams.get('machineNo');
	const outletCode = searchParams.get('outletCode');

	// console.log('operatorCode', operatorCode);
	useEffect(() => {
		const redirectUrl = 'https://app.octopus-laundry.com/welcome?';
		const cuciRedirectUrl = 'https://loyalty.cuciexpress.asia/welcome?';
		const washstudioUrl = 'https://loyalty.mywashstudio.com/welcome?';
		const bubblespulseUrl = 'https://loyalty.bubblespulse.com.my/welcome?';
		axios
			.get(`${checkDoPaymentUrl}?operatorCode=${operatorCode}`)
			.then((response) => {
				// // redirect to
				console.log('response', response);
				if (response.data) {
					if (window.location.hostname.includes('cuciexpress')) {
						window.location.replace(
							`${cuciRedirectUrl}machineNo=${machineNo}&outletCode=${outletCode}&operatorCode=${response.data.data}&opId=${operatorCode}`
						);
					} else if (window.location.hostname.includes('mywashstudio')) {
						window.location.replace(
							`${washstudioUrl}machineNo=${machineNo}&outletCode=${outletCode}&operatorCode=${response.data.data}&opId=${operatorCode}`
						);
					} else if (window.location.hostname.includes('bubblespulse')) {
						window.location.replace(
							`${bubblespulseUrl}machineNo=${machineNo}&outletCode=${outletCode}&operatorCode=${response.data.data}&opId=${operatorCode}`
						);
					} else if (window.location.hostname.includes('octopus')) {
						console.log('response', response);
						const resOperatorCode = response.data.data;

						const finalUrl = `${redirectUrl}machineNo=${machineNo}&outletCode=${outletCode}&operatorCode=${resOperatorCode}&opId=${operatorCode}`;

						// https://app.octopus-laundry.com/welcome?machineNo=uthma11-d1&outletCode=c64a3d60153ede56707fe4aaf11a416cb6e1bb0677d3e870f09d2eae711ac731&operatorCode=632c88e8244e5d3394552639&opId=c31a7d7b1f70c35a3a31e6aab413152ce1bfbd5f3589b92c

						// console.log('finalUrl', finalUrl);

						window.location.replace(finalUrl);
					} else {
						return setText('Server Error, please scan the correct qr code.');
					}
				}
			})
			.catch((error) => {
				// const finalUrl = `${redirectUrl}machineNo=${machineNo}&outletCode=${outletCode}&operatorCode=${operatorCode}`;
				// window.location.replace(finalUrl);

				if (window.location.hostname.includes('cuciexpress')) {
					window.location.replace(
						`${cuciRedirectUrl}machineNo=${machineNo}&outletCode=${outletCode}&opId=${operatorCode}`
					);
				} else if (window.location.hostname.includes('mywashstudio')) {
					window.location.replace(
						`${washstudioUrl}machineNo=${machineNo}&outletCode=${outletCode}&opId=${operatorCode}`
					);
				} else if (window.location.hostname.includes('bubblespulse')) {
					window.location.replace(
						`${bubblespulseUrl}machineNo=${machineNo}&outletCode=${outletCode}&opId=${operatorCode}`
					);
				} else if (window.location.hostname.includes('octopus')) {
					const finalUrl = `${redirectUrl}machineNo=${machineNo}&outletCode=${outletCode}&opId=${operatorCode}`;

					// https://app.octopus-laundry.com/welcome?machineNo=uthma11-d1&outletCode=c64a3d60153ede56707fe4aaf11a416cb6e1bb0677d3e870f09d2eae711ac731&operatorCode=632c88e8244e5d3394552639&opId=c31a7d7b1f70c35a3a31e6aab413152ce1bfbd5f3589b92c

					// console.log('finalUrl', finalUrl);

					window.location.replace(finalUrl);
				} else {
					return setText('Server Error, please scan the correct qr code.');
				}
			});
	});

	return (
		<div
			className={
				text === 'Server Error, please scan the correct qr code.' ? 'text-center py-5 text-danger' : 'text-center py-5'
			}
		>
			{text}
		</div>
	);
};

export default DoPaymentCheck;
