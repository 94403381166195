// costingStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    getCostingTypeAction,
    addCostingTypeAction,
    assignCostingTypeAction,
    editCostingTypeAction,
    deleteCostingTypeAction,
    ManageCostingAction,
    fetchCostingOutletListAction
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_COSTING_TYPE_LOADING = 'SET_COSTING_TYPE_LOADING';
const CLEAR_COSTING_TYPE_ERROR = 'CLEAR_COSTING_TYPE_ERROR';

const ADD_COSTING_TYPE_SUCCESS = 'ADD_COSTING_TYPE_SUCCESS';
const ADD_COSTING_TYPE_FAIL = 'ADD_COSTING_TYPE_FAIL';

const ASSIGN_COSTING_TYPE_SUCCESS = 'ASSIGN_COSTING_TYPE_SUCCESS';
const ASSIGN_COSTING_TYPE_FAIL = 'ASSIGN_COSTING_TYPE_FAIL';

const GET_COSTING_TYPE_LIST_SUCCESS = 'GET_COSTING_TYPE_LIST_SUCCESS';
const GET_COSTING_TYPE_LIST_FAIL = 'GET_COSTING_TYPE_LIST_FAIL';

const DELETE_COSTING_TYPE_SUCCESS = 'DELETE_COSTING_TYPE_SUCCESS';
const DELETE_COSTING_TYPE_FAIL = 'DELETE_COSTING_TYPE_FAIL';

const UPDATE_COSTING_TYPE_SUCCESS = 'UPDATE_COSTING_TYPE_SUCCESS';
const UPDATE_COSTING_TYPE_FAIL = 'UPDATE_COSTING_TYPE_FAIL';

const CLEAR_REMOVED_FLAG = 'CLEAR_REMOVED_FLAG';

const MANAGE_COSTING_SUCCESS = 'MANAGE_COSTING_SUCCESS';
const MANAGE_COSTING_FAIL = 'MANAGE_COSTING_FAIL';

const FETCH_COSTING_DETAILS = 'FETCH_COSTING_DETAILS';
const FETCH_COSTING_DETAILS_FAIL = 'FETCH_COSTING_DETAILS_FAIL';


// FOR COSTING TYPE SETTING PAGE

export const getCostingType = (operatorId) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_COSTING_TYPE_ERROR });
        dispatch({ type: SET_COSTING_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await getCostingTypeAction(operatorId, token);

        dispatch({ type: GET_COSTING_TYPE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_COSTING_TYPE_LIST_FAIL, payload: returnError(error) });
    }
};

export const addCostingType = (newCostingType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_COSTING_TYPE_ERROR });
        dispatch({ type: SET_COSTING_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await addCostingTypeAction(newCostingType, token);

        dispatch({ type: ADD_COSTING_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADD_COSTING_TYPE_FAIL, payload: returnError(error) });
    }
};

export const assignCostingType = (newCostingType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_COSTING_TYPE_ERROR });
        dispatch({ type: SET_COSTING_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await assignCostingTypeAction(newCostingType, token);

        dispatch({ type: ASSIGN_COSTING_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ASSIGN_COSTING_TYPE_FAIL, payload: returnError(error) });
    }
};


export const editCostingType = (updateData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_COSTING_TYPE_ERROR });
        dispatch({ type: SET_COSTING_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await editCostingTypeAction(updateData, token);

        dispatch({ type: UPDATE_COSTING_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_COSTING_TYPE_FAIL, payload: returnError(error) });
    }
};

export const deleteCostingType = (resBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_COSTING_TYPE_ERROR });
        dispatch({ type: SET_COSTING_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await deleteCostingTypeAction(resBody, token);

        dispatch({ type: DELETE_COSTING_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: DELETE_COSTING_TYPE_FAIL, payload: returnError(error) });
    }
};

export const clearRemovedFlag = () => ({
    type: CLEAR_REMOVED_FLAG,
});

export const fetchCostingOutletList = (reqBody) => async (dispatch, getState) => {
    try {
        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchCostingOutletListAction(reqBody, token);

        dispatch({ type: FETCH_COSTING_DETAILS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_COSTING_DETAILS_FAIL,
            payload: returnError(error),
        });
    }
};

export const ManageCosting = (reqBody) => async (dispatch, getState) => {
    try {
        const { user } = getState();
        const token = getToken(user);

        const { data } = await ManageCostingAction(reqBody, token);

        dispatch({ type: MANAGE_COSTING_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: MANAGE_COSTING_FAIL, payload: returnError(error) });
    }
};


const initialState = {
    language: 'en',
    costingTypeList: null,
    loading: false,
    costingTypeLists: [],
    costingDetails: [],
};

export const costingTypeSettingReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_COSTING_TYPE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_COSTING_TYPE_ERROR:
            return {
                ...state,
                error: null,
            };

        case GET_COSTING_TYPE_LIST_SUCCESS:
            return {
                ...state,
                costingTypeLists: action.payload.data,
                loading: false,
                error: null,
            };
        case ADD_COSTING_TYPE_SUCCESS:
            return {
                ...state,
                costingType: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case ASSIGN_COSTING_TYPE_SUCCESS:
            return {
                ...state,
                assigned: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case UPDATE_COSTING_TYPE_SUCCESS:
            return {
                ...state,
                costingTypeUpdated: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case DELETE_COSTING_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                removed: true,
            };
            
        case MANAGE_COSTING_SUCCESS:
            return {
                ...state,
                costingPriceUpdated: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case FETCH_COSTING_DETAILS:
            return {
                ...state,
                costingDetails: action.payload.data,
                loading: false,
                error: null,
            };

        case CLEAR_REMOVED_FLAG:
            return {
                ...state,
                removed: false,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        case GET_COSTING_TYPE_LIST_FAIL:
        case ASSIGN_COSTING_TYPE_FAIL:
        case UPDATE_COSTING_TYPE_FAIL:
        case DELETE_COSTING_TYPE_FAIL:
        case MANAGE_COSTING_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
