// stockUpdateReducer.js
import { getToken, returnError } from './helperFunc';
import { LOGOUT_SUCCESS } from './userReducer';
import { fetchChemicalOutletListAction, ManageChemicalsAction, fetchStockLogsAction, fetchStockLogsAction2 } from './requestEndpoints';

const SET_LOADING_STOCK_REPORT = 'SET_LOADING_STOCK_REPORT';
const CLEAR_ERROR_STOCK_REPORT = 'CLEAR_ERROR_STOCK_REPORT';

const FETCH_CHEMICAL_DETAILS = 'FETCH_CHEMICAL_DETAILS';
const FETCH_CHEMICAL_DETAILS_FAIL = 'FETCH_CHEMICAL_DETAILS_FAIL';
const FETCH_STOCK_LOGS = 'FETCH_STOCK_LOGS';
const FETCH_STOCK_LOGS_FAIL = 'FETCH_STOCK_LOGS_FAIL';

const Manage_Chemicals = 'Manage_Chemicals';
const Manage_Chemicals_FAIL = 'Manage_Chemicals_FAIL';

const FETCH_STOCK_LOGS2 = 'FETCH_STOCK_LOGS2';
const FETCH_STOCK_LOGS2_FAIL = 'FETCH_STOCK_LOGS2_FAIL';

export const fetchChemicalOutletList = (reqBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_ERROR_STOCK_REPORT });
        dispatch({ type: SET_LOADING_STOCK_REPORT });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchChemicalOutletListAction(reqBody, token);

        dispatch({ type: FETCH_CHEMICAL_DETAILS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_CHEMICAL_DETAILS_FAIL,
            payload: returnError(error),
        });
    }
};

export const fetchStockLogs = (reqBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_ERROR_STOCK_REPORT });
        dispatch({ type: SET_LOADING_STOCK_REPORT });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchStockLogsAction(reqBody, token);

        dispatch({ type: FETCH_STOCK_LOGS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_STOCK_LOGS_FAIL,
            payload: returnError(error),
        });
    }
};

export const fetchStockLogs2 = (reqBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_ERROR_STOCK_REPORT });
        dispatch({ type: SET_LOADING_STOCK_REPORT });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await fetchStockLogsAction2(reqBody, token);

        dispatch({ type: FETCH_STOCK_LOGS2, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_STOCK_LOGS2_FAIL,
            payload: returnError(error),
        });
    }
};

export const ManageChemicals = (reqBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_ERROR_STOCK_REPORT });
        dispatch({ type: SET_LOADING_STOCK_REPORT });

        const { user } = getState();
        const token = getToken(user);

        const response = await ManageChemicalsAction(reqBody, token);

        if (response.status === 201) {
            dispatch({
                type: Manage_Chemicals,
                payload: response.data.log
            });
            dispatch(fetchChemicalOutletList({ outletId: reqBody.outletId }));
        } else {
            throw new Error('Failed to update stock.');
        }
    } catch (error) {
        console.error('Error managing stock:', error);
        dispatch({
            type: Manage_Chemicals_FAIL,
            payload: returnError(error),
        });
    }
};

const initialState = {
    loading: false,
    chemicalDetails2: [],
    stockLogs: [],
    stockLogs2: [],
    error: null,
};

export const stockManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING_STOCK_REPORT:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_ERROR_STOCK_REPORT:
            return {
                ...state,
                error: null,
            };

        case FETCH_CHEMICAL_DETAILS:
            return {
                ...state,
                chemicalDetails2: action.payload.data,
                loading: false,
                error: null,
            };

        case FETCH_STOCK_LOGS:
            return {
                ...state,
                stockLogs: action.payload.data,
                loading: false,
                error: null,
            };

        case FETCH_STOCK_LOGS2:
            return {
                ...state,
                stockLogs2: action.payload.data,
                loading: false,
                error: null,
            };

        case FETCH_STOCK_LOGS_FAIL:
        case FETCH_STOCK_LOGS2_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        default:
            return state;
    }
};