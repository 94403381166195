import { getToken, returnError } from './helperFunc';
import {
	getAllGasSensorsAction,
	getRecordsOfUtilityAction,
	resetVolumeAction,
	refillVolumeAction,
} from './requestEndpoints';
import { LOGOUT_SUCCESS } from './userReducer';

const SET_UTILITY_LOADING = 'SET_UTILITY_LOADING';
const CLEAR_UTILITY_ERROR = 'CLEAR_UTILITY_ERROR';

const GET_GAS_SENSORS_SUCCESS = 'GET_GAS_SENSORS_SUCCESS';
const GET_GAS_SENSORS_FAIL = 'GET_GAS_SENSORS_FAIL';

const GET_RECORD_GAS_SENSORS_SUCCESS = 'GET_RECORD_GAS_SENSORS_SUCCESS';
const GET_RECORD_GAS_SENSORS_FAIL = 'GET_RECORD_GAS_SENSORS_FAIL';

const RESET_VOLUME_SUCCESS = 'RESET_VOLUME_SUCCESS';
const RESET_VOLUME_FAIL = 'RESET_VOLUME_FAIL';

const REFILL_VOLUME_SUCCESS = 'REFILL_VOLUME_SUCCESS';
const REFILL_VOLUME_FAIL = 'REFILL_VOLUME_FAIL';

// FOR OUTLETS

export const getAllGasSensors = (operatorId, outletSingleId) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_UTILITY_ERROR });
		dispatch({ type: SET_UTILITY_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await getAllGasSensorsAction(operatorId, outletSingleId, token);
		// console.log('data', data)
		dispatch({ type: GET_GAS_SENSORS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: GET_GAS_SENSORS_FAIL, payload: returnError(error) });
	}
};

export const getAllRecordsOfUtility = (reqBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_UTILITY_ERROR });
		dispatch({ type: SET_UTILITY_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await getRecordsOfUtilityAction(reqBody, token);
		// console.log('data', data)
		dispatch({ type: GET_RECORD_GAS_SENSORS_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: GET_RECORD_GAS_SENSORS_FAIL,
			payload: returnError(error),
		});
	}
};

export const resetSensorVolume = (reqBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_UTILITY_ERROR });
		dispatch({ type: SET_UTILITY_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await resetVolumeAction(reqBody, token);

		dispatch({ type: RESET_VOLUME_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: RESET_VOLUME_FAIL, payload: returnError(error) });
	}
};

export const refillSensorVolume = (reqBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_UTILITY_ERROR });
		dispatch({ type: SET_UTILITY_LOADING });

		const { user } = getState();
		const token = getToken(user);

		const { data } = await refillVolumeAction(reqBody, token);

		dispatch({ type: REFILL_VOLUME_SUCCESS, payload: data });
	} catch (error) {
		dispatch({ type: REFILL_VOLUME_FAIL, payload: returnError(error) });
	}
};

const initialState = {
	language: 'en',
	operator: null,
	error: null,
	loading: false,
	gasSensors: [],
};

export const utilityReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_UTILITY_LOADING:
			return {
				...state,
				loading: true,
				refillGas: null,
				resetGas: null,
			};

		case CLEAR_UTILITY_ERROR:
			return {
				...state,
				error: null,
			};

		case GET_GAS_SENSORS_SUCCESS:
			return {
				...state,
				gasSensors: action.payload.data,
				loading: false,
				error: null,
			};

		case RESET_VOLUME_SUCCESS:
			return {
				...state,
				refillGas: null,
				resetGas: action.payload.data,
				loading: false,
				error: null,
			};
		case REFILL_VOLUME_SUCCESS:
			return {
				...state,
				refillGas: action.payload.data,
				resetGas: null,
				loading: false,
				error: null,
			};

		case GET_RECORD_GAS_SENSORS_SUCCESS:
			return {
				...state,
				util_records: action.payload.data,
				loading: false,
				error: null,
			};

		case LOGOUT_SUCCESS:
			return initialState;

		case RESET_VOLUME_FAIL:
		case GET_GAS_SENSORS_FAIL:
		case GET_RECORD_GAS_SENSORS_FAIL:
		case REFILL_VOLUME_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
				refillGas: null,
				resetGas: null,
			};

		default:
			return state;
	}
};
