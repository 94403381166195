// costingStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    importDataAction,
    getDataAction,
    getTerminalAction
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const IMPORT_DATA_SUCCESS = 'IMPORT_DATA_SUCCESS';
const IMPORT_DATA_FAIL = 'IMPORT_DATA_FAIL';

const CLEAR_IMPORT_DATA_ERROR = 'CLEAR_IMPORT_DATA_ERROR';
const SET_IMPORT_DATA_LOADING = 'SET_IMPORT_DATA_LOADING'

const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';
const GET_DATA_FAIL = 'GET_DATA_FAIL';

const CLEAR_GET_DATA_ERROR = 'CLEAR_GET_DATA_ERROR';
const SET_GET_DATA_LOADING = 'SET_GET_DATA_LOADING'


const GET_TERMINAL_SUCCESS = 'GET_TERMINAL_SUCCESS';
const GET_TERMINAL_FAIL = 'GET_TERMINAL_FAIL';

const CLEAR_GET_TERMINAL_ERROR = 'CLEAR_GET_TERMINAL_ERROR';
const SET_GET_TERMINAL_LOADING = 'SET_GET_TERMINAL_LOADING'


export const importData = (newData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_IMPORT_DATA_ERROR });
        dispatch({ type: SET_IMPORT_DATA_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await importDataAction(newData, token);

        dispatch({ type: IMPORT_DATA_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: IMPORT_DATA_FAIL, payload: returnError(error) });
    }
};

export const getData = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_GET_DATA_ERROR });
        dispatch({ type: SET_GET_DATA_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await getDataAction(token);

        dispatch({ type: GET_DATA_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_DATA_FAIL, payload: returnError(error) });
    }
};

export const getTerminal = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_GET_TERMINAL_ERROR });
        dispatch({ type: SET_GET_TERMINAL_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await getTerminalAction(token);

        dispatch({ type: GET_TERMINAL_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_TERMINAL_FAIL, payload: returnError(error) });
    }
};



const initialState = {
    language: 'en',
    loading: false,
};

export const importDataReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_IMPORT_DATA_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_IMPORT_DATA_ERROR:
            return {
                ...state,
                error: null,
            };

        case SET_GET_DATA_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_GET_DATA_ERROR:
            return {
                ...state,
                error: null,
            };


        case SET_GET_TERMINAL_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_GET_TERMINAL_ERROR:
            return {
                ...state,
                error: null,
            };

        case IMPORT_DATA_SUCCESS:
            return {
                ...state,
                completed: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case GET_DATA_SUCCESS:
            return {
                ...state,
                processData: action.payload,
                loading: false,
                error: null,
                success: true,
            };

        case GET_TERMINAL_SUCCESS:
            return {
                ...state,
                terminalData: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case IMPORT_DATA_FAIL:
        case GET_DATA_FAIL:
        case GET_TERMINAL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
