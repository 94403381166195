import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const LanguageChanger = () => {
	const { i18n } = useTranslation();

	const language = localStorage.getItem('laundro-language');

	const handleChange = (e) => {
		if (e) {
			localStorage.setItem('laundro-language', e);
			i18n.changeLanguage(e);
		}
	};

	const languageOptions = [
		{
			value: 'en',
			label: 'English',
		},
		{
			value: 'ms',
			label: 'Malay',
		},
		{
			value: 'zh',
			label: 'Chinese (Simplified)',
		},
		{
			value: 'zhtw',
			label: 'Chinese (Traditional)',
		},
	];

	return (
		<Select
			// placeholder={language ? language.toUpperCase() : 'English'}
			style={{ width: '10rem' }}
			onChange={handleChange}
			options={languageOptions}
			value={language ? languageOptions?.find((each) => each.value === language)?.label : 'English'}
		/>
	);
};

export default LanguageChanger;
