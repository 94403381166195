/* eslint-disable no-unused-vars */
//coinCollectingReportReducer.js
import { getToken, returnError } from './helperFunc';
import {
	assignCoinCollectionAction,
	editCoinCollectionAction,
	getCoinCollectionByOutletAction, //
	getCoinCollectionByMachineAction, //
	getBillCollectionReportAction, //
	refillCoinInventoryAction, //
	resetCoinInventoryAction, //
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_LOADING_COIN_REPORT = 'SET_LOADING_COIN_REPORT';
const CLEAR_ERROR_COIN_REPORT = 'CLEAR_ERROR_COIN_REPORT';

const GET_REPORTS_BY_OUTLETS = 'GET_REPORTS_BY_OUTLETS';
const GET_REPORTS_BY_OUTLETS_FAIL = 'GET_REPORTS_BY_OUTLETS_FAIL';

const GET_REPORTS_BY_MACHINES = 'GET_REPORTS_BY_MACHINES';
const GET_REPORTS_BY_MACHINES_FAIL = 'GET_REPORTS_BY_MACHINES_FAIL';

const GET_BILL_COLLECTION_REPORTS = 'GET_BILL_COLLECTION_REPORTS';
const GET_BILL_COLLECTION_REPORTS_FAIL = 'GET_BILL_COLLECTION_REPORTS_FAIL';

const ASSIGN_COIN_COLLECTION_SUCCESS = 'ASSIGN_COIN_COLLECTION_SUCCESS';
const ASSIGN_COIN_COLLECTION_FAIL = 'ASSIGN_COIN_COLLECTION_FAIL';

const UPDATE_COIN_COLLECTION_SUCCESS = 'UPDATE_COIN_COLLECTION_SUCCESS';
const UPDATE_COIN_COLLECTION_FAIL = 'UPDATE_COIN_COLLECTION_FAIL';

const GET_COIN_COLLECTIONS_SUCCESS = 'GET_COIN_COLLECTIONS_SUCCESS';
const GET_COIN_COLLECTIONS_FAIL = 'GET_COIN_COLLECTIONS_FAIL';

const RESET_COIN_SUCCESS = 'RESET_COIN_SUCCESS';
const RESET_COIN_FAIL = 'RESET_COIN_FAIL';

const REFILL_COIN_SUCCESS = 'REFILL_COIN_SUCCESS';
const REFILL_COIN_FAIL = 'REFILL_COIN_FAIL';

const CLEAR_COIN_REPORT = 'CLEAR_COIN_REPORT';

// coin_collection/dashboard

export const getCoinCollectionReportsByOutlets = (reqBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCoinCollectionByOutletAction(reqBody, token);

		dispatch({ type: GET_REPORTS_BY_OUTLETS, payload: data });
	} catch (error) {
		dispatch({
			type: GET_REPORTS_BY_OUTLETS_FAIL,
			payload: returnError(error),
		});
	}
};

export const getCoinCollectionReportsByMachines = (reqBody) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getCoinCollectionByMachineAction(reqBody, token);

		dispatch({ type: GET_REPORTS_BY_MACHINES, payload: data });
	} catch (error) {
		dispatch({
			type: GET_REPORTS_BY_MACHINES_FAIL,
			payload: returnError(error),
		});
	}
};

export const assignCoinCollection = (newPrice) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await assignCoinCollectionAction(newPrice, token);

		dispatch({ type: ASSIGN_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: ASSIGN_COIN_COLLECTION_FAIL,
			payload: returnError(error),
		});
	}
};

export const updateCoinCollection = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await editCoinCollectionAction(updateData, token);

		dispatch({ type: UPDATE_COIN_COLLECTION_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: UPDATE_COIN_COLLECTION_FAIL,
			payload: returnError(error),
		});
	}
};

export const getBillCollectionReport = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await getBillCollectionReportAction(updateData, token);

		dispatch({ type: GET_BILL_COLLECTION_REPORTS, payload: data });
	} catch (error) {
		dispatch({
			type: GET_BILL_COLLECTION_REPORTS_FAIL,
			payload: returnError(error),
		});
	}
};

export const refillCoinRemaining = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await refillCoinInventoryAction(updateData, token);

		dispatch({ type: REFILL_COIN_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: REFILL_COIN_FAIL,
			payload: returnError(error),
		});
	}
};

export const resetCoinRemaining = (updateData) => async (dispatch, getState) => {
	try {
		dispatch({ type: CLEAR_ERROR_COIN_REPORT });
		dispatch({ type: SET_LOADING_COIN_REPORT });

		const { user } = getState();
		// console.log('user', user);
		const token = getToken(user);

		const { data } = await resetCoinInventoryAction(updateData, token);

		dispatch({ type: RESET_COIN_SUCCESS, payload: data });
	} catch (error) {
		dispatch({
			type: RESET_COIN_FAIL,
			payload: returnError(error),
		});
	}
};

const initialState = {
	language: 'en',
	loading: false,
	coinCollectionReports: [],
	coinRefilled: null,
	coinReset: null,
};

export const coinCollectingReportReducer = function (state = initialState, action) {
	switch (action.type) {
		// this is for fetching loading time setter
		case SET_LOADING_COIN_REPORT:
			return {
				...state,
				loading: true,
				coinRefilled: null,
				coinReset: null,
			};

		case CLEAR_COIN_REPORT:
			return {
				...state,
				coinCollectionReports: [],
				billCollectionReport: [],
				machinesReportsHeaders: [],
				billReportsHeaders: [],
				loading: false,
				error: null,
			};

		case CLEAR_ERROR_COIN_REPORT:
			return {
				...state,
				error: null,
			};

		case GET_REPORTS_BY_OUTLETS:
			return {
				...state,
				coinCollectionReports: action.payload.data,
				loading: false,
				error: null,
			};

		case GET_REPORTS_BY_MACHINES:
			return {
				...state,
				coinCollectionMachinesReports: action.payload.data,
				machinesReportsHeaders: action.payload.machines,
				loading: false,
				error: null,
			};

		case GET_BILL_COLLECTION_REPORTS:
			return {
				...state,
				billCollectionReport: action.payload.data,
				billReportsHeaders: action.payload.machines,
				loading: false,
				error: null,
			};

		case ASSIGN_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				assigned: action.payload.data,
				loading: false,
				error: null,
			};
		case UPDATE_COIN_COLLECTION_SUCCESS:
			return {
				...state,
				coinCollectionUpdated: action.payload.data,
				loading: false,
				error: null,
				success: true,
			};

		case REFILL_COIN_SUCCESS:
			return {
				...state,
				coinRefilled: action.payload.data,
				coinReset: null,
				loading: false,
				error: null,
			};

		case RESET_COIN_SUCCESS:
			return {
				...state,
				coinReset: action.payload.data,
				coinRefilled: null,
				loading: false,
				error: null,
			};

		case LOGOUT_SUCCESS:
			return initialState;

		case ASSIGN_COIN_COLLECTION_FAIL:
		case UPDATE_COIN_COLLECTION_FAIL:
		case GET_COIN_COLLECTIONS_FAIL:
		case GET_REPORTS_BY_MACHINES_FAIL:
		case RESET_COIN_FAIL:
		case REFILL_COIN_FAIL:
		case GET_BILL_COLLECTION_REPORTS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};
